<template>
  <div style="background-color: #fafafa">
    <GridList name="materialGrid" title="发货管理" port-name="fr.order.out.LoadCarOrder.getLoadCarOrders"
              :request-data="{}"
              :dataList="dataList" style="margin: 4.26667vw; height: 144vw" ref="grid">
      <ListCard
          v-for="item in dataList"
          :key="item.id"
          :id="item.id"
      >
        <div class="item" @click="toDetail(item)">
          <div class="content">
            <div class="tags fs16">{{'名称：' + item['name']}}</div>
            <div class="tags fs12">{{'编码：' + item['code']}}</div>
            <div class="tags fs12">{{'状态：' + item['statusDic.name']}}</div>
            <div class="tags fs12">{{'发货方式：' + item['shipperTypeDic.name']}}</div>
            <div class="tags fs12">{{'车牌号：' + item['headCarCode']}}</div>
          </div>
        </div>
      </ListCard>
    </GridList>
  </div>

  <van-action-bar style="bottom: 13.33333vw">
    <van-action-bar-button type="primary" text="新增" @click="addCmsOutStock"/>
  </van-action-bar>
</template>

<script>
import ListCard from "../../components/ListCard";
import GridList from "../../components/GridList";

export default {
  name: "LoadcarorderNew",
  components: {ListCard, GridList},

  data() {
    return {
      dataList: []
    };
  },
  created() {
  },
  methods: {
    toDetail: function (item) {

      this.$router.push({
        path: 'loadcarorderNewDetail',
        query: {loadCarId: item.id, loadCarName: item.name, loadCarCode: item.code,
          shipType: item['shipperTypeDic.id'],headCarCode:item['headCarCode'],typeDic:item['typeDic.id'],
          driverName:item.driverName,statusDic:item['statusDic.code']}
      })
    },
    addCmsOutStock: function () {
      var me = this;
      me.$router.push({
        path: 'loadcarorderNewDetail',
      })
    },
  }

}
</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>